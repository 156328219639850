<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<main id="main" role="main" class="pb-0">
  <div class="container-fluid">
    <div class="row justify-content-end customerTitle align-items-center">
      <div class="col-lg-4 col-md-12 d-flex align-items-center">
        <h1 class="mb-1">Docs Classification</h1>
        <button
          [disabled]="countSaveChangesforUndo === 0"
          class="btn btn-sm btn-outline-secondary ms-4"
          (click)="openDialog('300ms', '200ms')"
          (keydown)="openDialog('300ms', '200ms')"
          data-bs-toggle="tooltip"
          title="Note: Clicking reset will revert all changes to the initial state, and this cannot be undone.">
          <i class="bi bi-exclamation-triangle me-1"></i> Reset
        </button>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- BreadScrumb -->
        <div class="d-flex align-items-center justify-content-end">
          <div
            *ngIf="selectedDocDesc"
            class="selectedDocDesc text-gray me-4"
            data-bs-toggle="tooltip"
            title="{{ mainDocument }} / {{ selectedDocDesc }} {{ getFormattedVersion() }} {{ getPageNoformatter() }}">
            <div>{{ mainDocument }} / {{ selectedDocDesc }} {{ getFormattedVersion() }} {{ getPageNoformatter() }}</div>
          </div>
          <button
            class="btn btn-sm btn-outline-primary me-3"
            [disabled]="countSaveChangesforUndo === 0"
            (click)="undo()"
            data-bs-toggle="tooltip"
            title="Note: Undo to revert the last saved change.">
            Undo
          </button>
          <button
            class="btn btn-sm btn-outline-primary me-3 text-nowrap"
            [disabled]="countSaveChangesforUndo === 0"
            (click)="onSaveProgress()"
            data-bs-toggle="tooltip"
            title="Note: Your changes are saved. Submit to finalize.">
            Save Progress
          </button>
          <button
            class="btn btn-sm btn-primary"
            color="dark"
            (click)="openDialogForSubmitChange('300ms', '200ms')"
            [disabled]="isSubmitDisabled">
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between gap-2 mb-1">
      <div class="d-flex align-items-center gap-2">
        <!-- Reset model popup -->
        <ng-template #dialogTemplate>
          <mat-dialog-content class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="60.141" height="60.141" viewBox="0 0 60.141 60.141">
              <g id="vuesax_linear_danger" data-name="vuesax/linear/danger" transform="translate(-428 -252)">
                <g id="danger" transform="translate(428 252)">
                  <path
                    id="Vector"
                    d="M0,0V12.529"
                    transform="translate(30.07 22.553)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M25.057,47.154H9.872c-8.7,0-12.329-6.215-8.119-13.807L9.571,19.264,16.938,6.033c4.46-8.044,11.778-8.044,16.238,0l7.367,13.256,7.818,14.083c4.21,7.593.551,13.807-8.119,13.807H25.057Z"
                    transform="translate(5.013 6.496)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H.023"
                    transform="translate(30.057 42.6)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path id="Vector-4" data-name="Vector" d="M0,0H60.141V60.141H0Z" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            <h2 mat-dialog-title class="mt-4 mb-3">Are you sure you want to reset?</h2>
            <span>All changes will be lost, and this action can’t be undone.</span>
          </mat-dialog-content>
          <mat-dialog-actions class="text-center">
            <button
              mat-stroked-button
              mat-dialog-close
              class="btn btn-outline-primary mt-4 me-4"
              (click)="resetSaveChanges()">
              Yes
            </button>
            <button mat-flat-button mat-dialog-close class="btn btn-primary mt-4">No</button>
          </mat-dialog-actions>
        </ng-template>

        <!-- dialog for the change tracking -->
        <ng-template #dialogTemplateTracking>
          <mat-dialog-content class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="60.141" height="60.141" viewBox="0 0 60.141 60.141">
              <g id="vuesax_linear_danger" data-name="vuesax/linear/danger" transform="translate(-428 -252)">
                <g id="danger" transform="translate(428 252)">
                  <path
                    id="Vector"
                    d="M0,0V12.529"
                    transform="translate(30.07 22.553)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M25.057,47.154H9.872c-8.7,0-12.329-6.215-8.119-13.807L9.571,19.264,16.938,6.033c4.46-8.044,11.778-8.044,16.238,0l7.367,13.256,7.818,14.083c4.21,7.593.551,13.807-8.119,13.807H25.057Z"
                    transform="translate(5.013 6.496)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H.023"
                    transform="translate(30.057 42.6)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path id="Vector-4" data-name="Vector" d="M0,0H60.141V60.141H0Z" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            <h2 mat-dialog-title class="mt-4 mb-3">Do you want to save the changes?</h2>
            <!-- <div>Are you sure you want to discard your changes?</div> -->
            <!-- <div>All unsaved changes will be lost.</div> -->
          </mat-dialog-content>

          <mat-dialog-actions class="dialog-actions text-center">
            <button
              mat-stroked-button
              (click)="closeDialog('cancel')"
              class="action-button btn btn-outline-primary mt-4 me-4">
              Save
            </button>
            <button
              mat-flat-button
              (click)="closeDialog('confirm')"
              (keydown)="closeDialog('confirm')"
              class="action-button btn btn-primary mt-4">
              Exit without Saving
            </button>
          </mat-dialog-actions>
        </ng-template>

        <ng-template #dialogTemplateSubmit>
          <mat-dialog-content class="text-center p-0">
            <svg xmlns=" http://www.w3.org/2000/svg" width="60.141" height="60.141" viewBox="0 0 60.141 60.141">
              <g id="vuesax_linear_danger" data-name="vuesax/linear/danger" transform="translate(-428 -252)">
                <g id="danger" transform="translate(428 252)">
                  <path
                    id="Vector"
                    d="M0,0V12.529"
                    transform="translate(30.07 22.553)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M25.057,47.154H9.872c-8.7,0-12.329-6.215-8.119-13.807L9.571,19.264,16.938,6.033c4.46-8.044,11.778-8.044,16.238,0l7.367,13.256,7.818,14.083c4.21,7.593.551,13.807-8.119,13.807H25.057Z"
                    transform="translate(5.013 6.496)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H.023"
                    transform="translate(30.057 42.6)"
                    fill="none"
                    stroke="#cc0028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5" />
                  <path id="Vector-4" data-name="Vector" d="M0,0H60.141V60.141H0Z" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            <h2 mat-dialog-title class="mt-4 mb-3">Submit all your changes?</h2>
          </mat-dialog-content>
          <mat-dialog-actions class="text-center">
            <button mat-flat-button mat-dialog-close class="btn btn-outline-primary mt-4 me-4">Cancel</button>
            <button mat-stroked-button mat-dialog-close class="btn btn-primary mt-4" (click)="postSubmit()">
              Submit
            </button>
          </mat-dialog-actions>
        </ng-template>
        <!-- dialog for the Reclassify pages -->
        <ng-template #dialogTemplateReclassify>
          <div mat-dialog-title="" class="mat-mdc-dialog-title mdc-dialog__title" id="mat-mdc-dialog-title-1">
            Reclassify Pages
          </div>

          <mat-dialog-content class="dialog-content Reclassify-Pages">
            <div class="pageSelected mt-4 mb-3">
              <!-- BreadCrumb here -->
              <div class="form-text" for="selectedPage">Selected Page</div>
              <div *ngIf="versionFileName && documentDetails?.originalVersion" class="size-normal">
                {{ getFormattedVersionWRemoveSlash() }} {{ getPageNoformatter() }}
              </div>
            </div>
            <div class="classify-item">
              <svg
                (click)="openDialogTracking('300ms', '200ms')"
                (keydown)="openDialogTracking('300ms', '200ms')"
                class="closed"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 22.88 22.88"
                style="enable-background: new 0 0 22.88 22.88"
                xml:space="preserve">
                <path
                  d="M0.324,1.909c-0.429-0.429-0.429-1.143,0-1.587c0.444-0.429,1.143-0.429,1.587,0l9.523,9.539
                                l9.539-9.539c0.429-0.429,1.143-0.429,1.571,0c0.444,0.444,0.444,1.159,0,1.587l-9.523,9.524l9.523,9.539
                                c0.444,0.429,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-9.539-9.539l-9.523,9.539c-0.444,0.429-1.143,0.429-1.587,0
                                c-0.429-0.444-0.429-1.159,0-1.587l9.523-9.539L0.324,1.909z" />
              </svg>

              <!-- Reclassify To -->
              <div class="mb-3">
                <div class="form-text" for="reclsToInput">Reclassify To</div>
                <input
                  id="reclsToInput"
                  type="text"
                  class="form-control"
                  placeholder="Select Document"
                  matInput
                  class="auto-field-input form-select"
                  [matAutocomplete]="reclassifyAuto"
                  [(ngModel)]="selectedReclsDesc"
                  (input)="filterReclassifyTypes($event)" />

                <mat-autocomplete #reclassifyAuto="matAutocomplete" class="select-auto-field">
                  <mat-option
                    *ngFor="let documentType of filteredReclsTypes"
                    [value]="documentType.description"
                    (onSelectionChange)="onReclassifySelect(documentType, $event)">
                    {{ documentType.description }}
                  </mat-option>
                  <mat-option *ngIf="filteredReclsTypes.length === 0" disabled>No results found</mat-option>
                </mat-autocomplete>
              </div>
              <div class="mb-3" *ngIf="boReclasShow">
                <div class="form-text" for="bTypeInput">Select Borrower</div>
                <input
                  id="bTypeInput"
                  type="text"
                  placeholder="Select"
                  matInput
                  class="form-control auto-field-input form-select"
                  [matAutocomplete]="reclassifyBorrowerauto"
                  [(ngModel)]="selectedVersionReclsBoName"
                  (input)="filterReclassifyBorrowers($event)" />

                <mat-autocomplete #reclassifyBorrowerauto="matAutocomplete" class="select-auto-field">
                  <button class="option-btn" (click)="createNewBorrower(lastBorrower)">Create Next Borrower</button>
                  <mat-option
                    *ngFor="let borrowerType of filterReclsBorrowerTypes"
                    [value]="borrowerType.versioning_Borrower_Name"
                    (onSelectionChange)="onReclassifyBorrowerSelect(borrowerType, $event)">
                    {{ borrowerType.versioning_Borrower_Name }}
                  </mat-option>
                  <mat-option *ngIf="filterReclsBorrowerTypes.length === 0" disabled>No results found</mat-option>
                </mat-autocomplete>
              </div>
              <div class="mb-3" *ngIf="versionReclasShow">
                <div class="form-text" for="changeVersionInput">Change Version</div>
                <input
                  id="changeVersionInput"
                  type="text"
                  placeholder="Select"
                  matInput
                  class="auto-field-input form-select"
                  [matAutocomplete]="versionType"
                  [(ngModel)]="selectedReclsVersionName"
                  (input)="filterReclassifyVersionsChanges($event)" />
                <mat-autocomplete #versionType="matAutocomplete" class="form-control select-auto-field">
                  <button class="option-btn" (click)="createNewVersion(lastVersion)">
                    Create Next Intermediate Version
                  </button>
                  <mat-option
                    *ngFor="let versions of filteredReclsVersionTypes"
                    [value]="versions.description"
                    (onSelectionChange)="onReclassifyChangeVersions(versions, $event)">
                    {{ versions.description }}
                  </mat-option>
                  <mat-option *ngIf="filteredReclsVersionTypes.length === 0" disabled>No results found</mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </mat-dialog-content>
          <mat-dialog-actions class="text-center mt-3">
            <!-- mat-flat-button color="dark" -->
            <button [disabled]="isDisabledReclass" class="btn btn-primary me-4" (click)="reclassifySubmit()">
              Save Changes
            </button>
            <span
              tabindex="0"
              class="btn btn-outline-primary"
              (keydown)="onSortListClick('300ms', '200ms')"
              (click)="onSortListClick('300ms', '200ms')"
              >Reclassify Multiple Pages</span
            >
          </mat-dialog-actions>
        </ng-template>
      </div>

      <span class="flex-grow"></span>

      <!-- 
        Note:: Add `disabled` attribute if you want to disabled button.     
    --></div>

    <mat-grid-list cols="12" rowHeight="55px" [gutterSize]="'1.5rem'" class="mb-1">
      <mat-grid-tile colspan="4" class="align-items-center">
        <!-- Select Document Type -->
        <label for="docTypeInput" class="inline-label mb-0">Select Document Type</label>
        <input
          id="docTypeInput"
          type="text"
          placeholder="Select Document"
          matInput
          class="auto-field-input form-select w-100"
          [matAutocomplete]="auto"
          [(ngModel)]="selectedDocDesc"
          (input)="filterDocuments($event)"
          (click)="clickOnDocumentDropdown('300ms', '200ms')" />

        <mat-autocomplete #auto="matAutocomplete" class="select-auto-field">
          <mat-option
            *ngFor="let documentType of filteredDocTypes"
            [value]="documentType.description"
            (onSelectionChange)="onDocumentSelect(documentType, $event)">
            {{ documentType.description }}
          </mat-option>
          <mat-option *ngIf="filteredDocTypes.length === 0" disabled>No results found</mat-option>
        </mat-autocomplete>
      </mat-grid-tile>
      <mat-grid-tile colspan="3" *ngIf="borrowerShow" class="align-items-center">
        <label for="borrowerTypeInput" class="inline-label mb-0">Select Borrower</label>
        <input
          id="borrowerTypeInput"
          type="text"
          placeholder="Select"
          matInput
          class="auto-field-input form-select"
          [matAutocomplete]="auto"
          [(ngModel)]="selectedVersionBoName"
          (input)="filterBorrowers($event)" />

        <mat-autocomplete #auto="matAutocomplete" class="select-auto-field">
          <mat-option
            *ngFor="let borrowerType of filteredBorrowerTypes"
            [value]="borrowerType.versioning_Borrower_Name"
            (onSelectionChange)="onBorrowerSelect(borrowerType, $event)">
            {{ borrowerType.versioning_Borrower_Name }}
          </mat-option>
          <mat-option *ngIf="filteredBorrowerTypes.length === 0" disabled>No results found</mat-option>
        </mat-autocomplete>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <span
          class="text-classify d-block pt-3"
          [ngClass]="{ active: isActive, disabled: showReclassifyMultiplePages }"
          (click)="isActive && toggleClass('300ms', '200ms')"
          (keydown)="toggleClass('300ms', '200ms')"
          role="button"
          aria-label="Reclassify Page(s)">
          Reclassify Page(s)
        </span>
      </mat-grid-tile>
    </mat-grid-list>
    <div *ngIf="notificationService.notifyMessage" class="badge warning-badge op-center badgeFixed">
      <i class="bi bi-exclamation-triangle"></i> {{ notificationService.notifyMessage }}
    </div>

    <div *ngIf="notificationService.successMessage" class="badge success-badge top-center badgeFixed">
      <i class="bi bi-check-circle"></i> {{ notificationService.successMessage }}
    </div>

    <div *ngIf="notificationService.errorMessage" class="badge error-badge top-center badgeFixed">
      <i class="bi bi-exclamation-triangle"></i> {{ notificationService.errorMessage }}
    </div>

    <mat-grid-list cols="5" class="maingridList">
      <mat-grid-tile colspan="1">
        <div class="w-100">
          <!--  
                    Note:: `splash-view` if you want to enable default.  
                -->
          <mat-card appearance="outlined" class="splash-view" *ngIf="showSplashDocTypeView">
            <mat-card-header>
              <mat-card-title>Document Type</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <span class="text-gray">Select Document Type to <br />start viewing the documents</span>
            </mat-card-content>
          </mat-card>
          <!--  
                    Note:: `document-view` if you want to enable default.  
                 -->
          <mat-card appearance="outlined" class="document-view" *ngIf="showDocumentList">
            <mat-card-header class="docTitle">
              <mat-card-title>
                <span data-bs-toggle="tooltip" title="{{ selectedDocDesc }}">{{
                  selectedDocDesc
                }}</span></mat-card-title
              >
            </mat-card-header>
            <mat-card-content>
              <mat-accordion multi="true" *ngIf="documentVersionList.length">
                <mat-expansion-panel
                  *ngFor="let version of documentVersionList; let isFirst = first"
                  [expanded]="isExpanded(version, isFirst)"
                  (opened)="selectedPanelOpen(version)"
                  (closed)="selectedPanelClosed()">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="docPageTitle" data-bs-toggle="tooltip" title="{{ version.file_Name }}">
                        <span>{{ version.file_Name }}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <a class="MultiDoc">
                    <img
                      [ngClass]="{ 'disabled-img': !showReorder }"
                      (keydown)="onReorderClick('300ms', '200ms')"
                      (click)="onReorderClick('300ms', '200ms')"
                      tabindex="0"
                      src="assets/images/multiDoc_Ico.png"
                      alt="Multi Document Icon" />
                  </a>
                  <div class="pdf-list">
                    <ul class="img-list">
                      <li
                        *ngFor="let image of thumbnails; let i = index"
                        [class.selected]="i === currentIndex"
                        #thumbnailElement>
                        <div class="imgThumbnail">
                          <img
                            class="w-100"
                            [src]="image.thumbnailPath"
                            alt="Page {{ i + 1 }}"
                            (keydown)="showImage(i, image)"
                            tabindex="0"
                            (click)="showImage(i, image)"
                            alt="Image" />
                        </div>
                        <div class="pageno">Page {{ i + 1 }}</div>
                      </li>
                    </ul>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-grid-tile>

      <mat-grid-tile colspan="4" class="align-flex-start">
        <div class="ml-2 w-100">
          <!--  
                    Note:: `splash-view` class if you want to enable default.  
                -->
          <mat-card appearance="outlined" class="splash-view" *ngIf="showSplashPageView">
            <mat-card-header>
              <mat-card-title />
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-12">
                  <img
                    src="assets/images/docs_default.png"
                    class="img-fluid"
                    alt="no docs_default"
                    draggable="false"
                    (dragstart)="(false)" />
                </div>
                <div class="col-12 mt-4">
                  <div class="w-100 text-gray">Your selected documents will be viewed here</div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--  
                    Note:: `noPage-view` class if you want to enable default.  
                -->
          <mat-card appearance="outlined" class="splash-view" *ngIf="isNoPage">
            <mat-card-header class="centered-header">
              <mat-card-title> {{ getFormattedVersionWRemoveSlash() }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-12">
                  <img
                    src="assets/images/nopagefound.png"
                    class="img-fluid"
                    alt="no page_available"
                    draggable="false"
                    (dragstart)="(false)" />
                </div>
                <div class="col-12 mt-4">
                  <div class="w-100 text-gray">There are no pages available in this document</div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <!--  
                    Note:: `document-view` if you want to enable default.    
                -->
          <mat-card appearance="outlined" class="document-view" *ngIf="showDocumentView">
            <mat-card-header>
              <mat-card-title>
                <span *ngIf="pageButtonShow" class="d-flex align-items-center gap-2">
                  <a
                    (keydown)="prevImage()"
                    (click)="prevImage()"
                    data-bs-toggle="tooltip"
                    title="Previous"
                    class="btn btn-sm btn-outline-primary me-3"
                    tabindex="0"
                    [class.disabled]="isPrevDisabled">
                    Prev
                  </a>
                  <a
                    (keydown)="nextImage()"
                    (click)="nextImage()"
                    data-bs-toggle="tooltip"
                    title="Next"
                    class="btn btn-sm btn-outline-primary"
                    tabindex="0"
                    [class.disabled]="isNextDisabled">
                    Next
                  </a>
                </span>
                <span class="text-gray">Page {{ pageNo }} </span>
                <a
                  *ngIf="imageShow"
                  tabindex="0"
                  class="d-flex align-items-center gap-1 text-bold cursor-pointer btn btn-sm btn-outline-primary"
                  (keydown)="fileDetails.toggle()"
                  (click)="fileDetails.toggle()">
                  <i class="bi bi-info-circle me-1"></i>
                  Page Details
                </a>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-sidenav-container>
                <mat-sidenav #fileDetails mode="side" position="end" class="fixed-end-sidenav">
                  <div>
                    <div>
                      <div class="text-gray size-normal line-hight">Last Updated</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.modified_At }}</div>
                    </div>
                    <div>
                      <div class="text-gray size-normal line-hight">Updated by</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.createdBy }}</div>
                    </div>
                    <div>
                      <div class="text-gray size-normal line-hight">Original Document Type</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.originalType }}</div>
                    </div>
                    <div>
                      <div class="text-gray size-normal line-hight">Original Version</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.originalVersion }}</div>
                    </div>
                    <div>
                      <div class="text-gray size-normal line-hight">Current Document Type</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.documentType }}</div>
                    </div>
                    <div>
                      <div class="text-gray size-normal line-hight">Current Version</div>
                      <div class="text-bold size-normal">{{ documentDetails?.version }}</div>
                    </div>
                    <div *ngIf="documentDetails?.currentBorrower">
                      <div class="text-gray size-normal line-hight">Current Borrower</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.currentBorrower }}</div>
                    </div>
                    <div *ngIf="documentDetails?.originalBorrower">
                      <div class="text-gray size-normal line-hight">Original Borrower</div>
                      <div class="text-bold size-normal line-hight">{{ documentDetails?.originalBorrower }}</div>
                    </div>
                  </div>
                </mat-sidenav>
                <mat-sidenav-content>
                  <section class="img-panel">
                    <div
                      class="img-view"
                      *ngIf="this.pageImage"
                      [style.width.px]="imageSize"
                      [style.height.px]="imageHeightSize">
                      <img [src]="this.pageImage" alt="Page not shown" class="img-fluid" />
                      <div class="zoom-container">
                        <button (click)="zoomIn()">
                          <svg
                            id="search-zoom-in"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              id="Vector"
                              d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z"
                              transform="translate(2 2)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,0H5"
                              transform="translate(8.5 11)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                            <path
                              id="Vector-3"
                              data-name="Vector"
                              d="M0,5V0"
                              transform="translate(11 8.5)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                            <path
                              id="Vector-4"
                              data-name="Vector"
                              d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                              transform="translate(18.83 18.72)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                            <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
                          </svg>
                        </button>
                        <button (click)="zoomOut()">
                          <svg
                            id="search-zoom-out"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              id="Vector"
                              d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z"
                              transform="translate(2 2)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                            <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
                            <path
                              id="Vector-3"
                              data-name="Vector"
                              d="M0,0H5"
                              transform="translate(8.5 11)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                            <path
                              id="Vector-4"
                              data-name="Vector"
                              d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                              transform="translate(18.83 18.72)"
                              fill="none"
                              stroke="#292d32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </section>
                </mat-sidenav-content>
              </mat-sidenav-container>
            </mat-card-content>
          </mat-card>
          <!--  
                    Note:: `document-list-view` if you want to enable default.    
                -->
          <mat-card appearance="outlined" class="document-list-view" *ngIf="showDocumentListView">
            <mat-card-header>
              <mat-card-title class="d-flex align-items-center justify-content-center text-gray">
                {{ versionFileName }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="pdf-list-view">
                <div cdkDropListGroup class="img-list-view" *ngIf="!showOneDocument">
                  <div
                    cdkDropList
                    [cdkDropListData]="thumbnails"
                    class="list-item-view example-boundary"
                    cdkDropListOrientation="mixed"
                    (cdkDropListDropped)="drop($event)">
                    <div
                      *ngFor="let item of thumbnails; let i = index"
                      cdkDrag
                      cdkDragBoundary=".example-boundary"
                      [cdkDragDisabled]="!selectedItems.has(i + 1)"
                      [ngClass]="{ selected: selectedItems.has(i + 1) }"
                      (mousedown)="$event.preventDefault()"
                      (cdkDragMoved)="onDragMoved($event)"
                      (keydown)="toggleReorderSelection(i, item, $event)"
                      (click)="toggleReorderSelection(i, item, $event)"
                      tabindex="0"
                      class="list-item image-container">
                      <div class="imgThumbnail">
                        <img [src]="item?.thumbnailPath" alt="Page {{ i + 1 }}" class="w-100" />
                        <ng-template cdkDragPreview>
                          <div class="drag-preview">
                            <img
                              *ngFor="let selected of getSelectedItems()"
                              [src]="selected.thumbnailPath"
                              class="preview-image"
                              alt="Preview {{ selected.pageNumber }}" />
                          </div>
                        </ng-template>
                      </div>
                      <div class="pageno">Page {{ i + 1 }}</div>
                      <img
                        src="assets/images/eye.png"
                        class="expand"
                        (keydown)="showEyeImage(item, i)"
                        (click)="showEyeImage(item, i)"
                        tabindex="0"
                        alt="Expand view" />
                      <!-- <img *cdkDragPreview src="/images/MultiplepageSelected.png" alt="image" /> -->
                    </div>
                  </div>
                </div>
                <!-- new mat card for view page -->
                <div class="expand-view car" *ngIf="showOneDocument">
                  <mat-card appearance="outlined">
                    <mat-card-header>
                      <mat-card-title class="d-flex justify-content-between align-items-center w-100">
                        <!-- Centered Page text -->
                        <span class="text-gray mx-auto">Page {{ this.currentIndex + 1 }}</span>
                        <!-- Close link -->
                        <span>
                          <a (keydown)="closePage()" (click)="closePage()" class="text-bold" tabindex="0">Close</a>
                        </span>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="img-view" *ngIf="pageImage">
                        <img
                          [src]="pageImage"
                          [style.width.px]="imageSize"
                          [style.height.px]="imageSize"
                          alt="no image" />
                        <div class="zoom-container">
                          <button (click)="zoomIn()">
                            <svg
                              id="search-zoom-in"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24">
                              <path
                                id="Vector"
                                d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z"
                                transform="translate(2 2)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,0H5"
                                transform="translate(8.5 11)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,5V0"
                                transform="translate(11 8.5)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                                transform="translate(18.83 18.72)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
                            </svg>
                          </button>
                          <button (click)="zoomOut()">
                            <svg
                              id="search-zoom-out"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24">
                              <path
                                id="Vector"
                                d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z"
                                transform="translate(2 2)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,0H5"
                                transform="translate(8.5 11)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                                transform="translate(18.83 18.72)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>

                <div class="pdf-list-sidebar">
                  <div class="p-2">
                    <div class="mb-2">
                      <div class="text-gray size-normal">Pages Selected</div>
                      <div *ngIf="!showOneDocument" class="text-bold size-normal">
                        {{ formattedPageSelection }}
                      </div>
                      <div *ngIf="showOneDocument" class="text-bold size-normal">{{ this.currentIndex + 1 }}</div>
                    </div>
                    @if (isButtonVisible) {
                      <div class="mb-4">
                        <button
                          [disabled]="isReorderChange"
                          mat-flat-button
                          class="btn btn-sm btn-outline-primary w-100"
                          (click)="saveReorderChanges()">
                          Save
                        </button>
                      </div>
                      <div class="mb-4">
                        <button
                          mat-flat-button
                          class="btn btn-sm btn-primary w-100"
                          (click)="saveAndExitReorderChanges()">
                          Save & Exit
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" class="document-list-view" *ngIf="showReclassifyMultiplePages">
            <mat-card-header>
              <mat-card-title class="d-flex align-items-center justify-content-center text-gray">
                {{ versionFileName }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="pdf-list-view">
                <div class="img-list-view" *ngIf="!showOneDocument">
                  <div class="list-item-view" style="display: flex">
                    <div
                      *ngFor="let item of thumbnails; let i = index"
                      [ngClass]="{ selected: selectedReclassifyItems.has(i + 1) }"
                      (keydown)="toggleMultipleSelection(i, item, $event)"
                      (mousedown)="$event.preventDefault()"
                      (click)="toggleMultipleSelection(i, item, $event)"
                      tabindex="0"
                      class="list-item">
                      <div class="imgThumbnail">
                        <img
                          [src]="item?.thumbnailPath"
                          alt="Page {{ i + 1 }}"
                          class="image"
                          tabindex="0"
                          alt="Expand view" />
                      </div>
                      <div class="pageno">Page {{ i + 1 }}</div>
                      <!-- <img src="/images/eye.png" class="expand" (keydown)="showEyeImage(item, i)"
                      (click)="showEyeImage(item, i)" tabindex="0" alt="Expand view" /> -->
                    </div>
                  </div>
                </div>
                <!-- new mat card for view page -->
                <div class="expand-view car" *ngIf="showOneDocument">
                  <mat-card appearance="outlined">
                    <mat-card-header>
                      <mat-card-title class="d-flex align-items-center justify-content-between text-gray">
                        <span class="text-gray">Page {{ this.currentIndex + 1 }} </span>
                        <span class="d-flex align-items-center gap-2">
                          <a (keydown)="closePage()" (click)="closePage()" class="text-bold" tabindex="0">Close</a>
                        </span>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="img-view" *ngIf="pageImage" style="overflow-x: auto">
                        <img
                          [src]="pageImage"
                          [style.width.px]="imageSize"
                          [style.height.px]="imageSize"
                          alt="no image" />
                        <div class="zoom-container">
                          <button (click)="zoomIn()">
                            <svg
                              id="search-zoom-in"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24">
                              <path
                                id="Vector"
                                d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z"
                                transform="translate(2 2)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,0H5"
                                transform="translate(8.5 11)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,5V0"
                                transform="translate(11 8.5)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                                transform="translate(18.83 18.72)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
                            </svg>
                          </button>
                          <button (click)="zoomOut()">
                            <svg
                              id="search-zoom-out"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24">
                              <path
                                id="Vector"
                                d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z"
                                transform="translate(2 2)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,0H5"
                                transform="translate(8.5 11)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                                transform="translate(18.83 18.72)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>

                <div class="pdf-list-sidebar">
                  <div class="p-2">
                    <div class="mb-2">
                      <div class="text-gray size-normal">Pages Selected</div>
                      <div *ngIf="!showOneDocument" class="text-bold size-normal">
                        {{ formattedPageSelection }}
                      </div>
                      <div *ngIf="showOneDocument" class="text-bold size-normal">{{ this.currentIndex + 1 }}</div>
                    </div>
                    <!-- Reclassify To -->
                    <div class="mb-2" *ngIf="!isButtonVisible">
                      <label class="text-gray size-normal" for="reclassifyToInput">Reclassify To</label>
                      <input
                        id="reclassifyToInput"
                        type="text"
                        placeholder="Select Document"
                        matInput
                        class="auto-field-input form-select"
                        [matAutocomplete]="reorderclassifyAuto"
                        [(ngModel)]="selectedMultiReclsDesc"
                        (input)="filterReorderReclassifyTypes($event)" />

                      <mat-autocomplete #reorderclassifyAuto="matAutocomplete" class="select-auto-field">
                        <mat-option
                          *ngFor="let documentType of filteredMultiReclsTypes"
                          [value]="documentType.description"
                          (onSelectionChange)="onReorderReclassifySelect(documentType, $event)">
                          {{ documentType.description }}
                        </mat-option>
                        <mat-option *ngIf="filteredMultiReclsTypes.length === 0" disabled>No results found</mat-option>
                      </mat-autocomplete>
                    </div>
                    <div class="mb-2" *ngIf="!isButtonVisible && boMultiReclasShow">
                      <div class="mb-2">
                        <label class="text-gray size-normal" for="versionInput">Select Borrower</label>
                        <input
                          id="versionInput"
                          type="text"
                          placeholder="Select"
                          matInput
                          class="auto-field-input form-select"
                          [matAutocomplete]="reorderReclassifyBorrowerauto"
                          [(ngModel)]="selectedVersionMultiReclsBoName"
                          (input)="filterReorderReclassifyBorrowers($event)" />

                        <mat-autocomplete #reorderReclassifyBorrowerauto="matAutocomplete" class="select-auto-field">
                          <ng-container *ngIf="filterMultiReclsBorrowerTypes.length; else noOptions">
                            <button class="option-btn" (click)="createNewBorrower(lastBorrower)">
                              Create Next Borrower
                            </button>
                            <mat-option
                              *ngFor="let borrowerType of filterMultiReclsBorrowerTypes"
                              [value]="borrowerType.versioning_Borrower_Name"
                              (onSelectionChange)="onReorderReclassifyBorrowerSelect(borrowerType, $event)">
                              {{ borrowerType.versioning_Borrower_Name }}
                            </mat-option>
                          </ng-container>
                          <ng-template #noOptions>
                            <mat-option disabled>No result Found</mat-option>
                          </ng-template>
                        </mat-autocomplete>
                      </div>
                    </div>

                    <div class="mb-4">
                      <ng-container *ngIf="!isButtonVisible && versionMultiReclasShow">
                        <div class="mb-2">
                          <label class="text-gray size-normal" for="versionInput">Change Version</label>
                          <input
                            id="versionInput"
                            type="text"
                            placeholder="Select"
                            matInput
                            class="auto-field-input form-select"
                            [matAutocomplete]="reorderReorderReclassifyVersionType"
                            [(ngModel)]="selectedMultiReclsVerName"
                            (input)="filterMultiReclassifyVersionChanges($event)" />

                          <mat-autocomplete
                            #reorderReorderReclassifyVersionType="matAutocomplete"
                            class="select-auto-field">
                            <ng-container *ngIf="filteredOptions.length; else noOptions">
                              <button class="option-btn" (click)="createNewVersionMultiple(lastVersion)">
                                Create Next Intermediate Version
                              </button>
                              <mat-option
                                *ngFor="let versions of filteredOptions"
                                [value]="versions.description"
                                (onSelectionChange)="onReorderReclassifyChangeVersions(versions, $event)">
                                {{ versions.description }}
                              </mat-option>
                            </ng-container>

                            <ng-template #noOptions>
                              <mat-option disabled>No results found</mat-option>
                            </ng-template>
                          </mat-autocomplete>
                        </div>
                      </ng-container>
                    </div>
                    @if (!isButtonVisible) {
                      <div class="mb-4">
                        <button
                          [disabled]="isSaveMultipleData"
                          mat-flat-button
                          class="btn btn-primary w-100"
                          (click)="saveMultiReclassifyChanges()">
                          Save Changes
                        </button>
                      </div>
                      <div class="mb-4 text-center">
                        <span
                          class="btn btn-outline-primary w-100"
                          (keydown)="selectAllPages()"
                          (click)="selectAllPages()"
                          tabindex="0"
                          >Select All Pages</span
                        >
                      </div>
                      <div class="text-center">
                        <span
                          class="btn btn-outline-secondary"
                          (keydown)="exitReclasMultiplePage()"
                          (click)="exitReclasMultiplePage()"
                          tabindex="0"
                          >Exit Reclassify Multiple</span
                        >
                      </div>
                    }
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</main>
