/* eslint-disable @typescript-eslint/no-unused-vars */

import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable } from "rxjs";
import {
  BorrowerMaster,
  CreateBorrowerVersion,
  CreateVersion,
  DocumentDetails,
  DocumentTypeByVersions,
  DocumentTypesMaster,
  PageDetail,
  PageImage,
  ResetChanges,
  SaveProcess,
  saveChanges,
} from "../../models/DocClassificationModels/_index";
import { SubmitChanges } from "../../models/DocClassificationModels/submit-change.model";
import { ApiGridResponseModel, rdDocumentTypeVersion } from "./constants";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  LoanStatusUrl = "https://msuiteloans-dev.azurewebsites.net/api/v1/projects/";
  baseUrl = "https://msuiteppa.azurewebsites.net/";
  submitChanges(submitChanges: SubmitChanges) {
    throw new Error("Method not implemented.");
  }
  //private jsonUrl = 'assets/jsonfold/data.json'; // Correct path
  constructor(
    private http: HttpClient,
    // @Inject("BASE_URL") private baseUrl: string,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  getDocumentList(type: number, loandId: string): Observable<ApiGridResponseModel<DocumentTypesMaster[]>> | undefined {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get<ApiGridResponseModel<DocumentTypesMaster[]>>(
        this.baseUrl + "api/v1/loans/" + loandId + "/document-types?type=" + type
      );
    }
    return undefined; // Explicitly return undefined when not on browser platform
  }

  getBorrowerList(loandId: string, documentType: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get<ApiGridResponseModel<BorrowerMaster[]>>(
        this.baseUrl + "api/v1/loans/" + loandId + "/borrowers?documentTypeId=" + documentType
      );
    }
    return undefined;
  }

  getDocumentVersions(documentType: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get<ApiGridResponseModel<DocumentTypesMaster[]>>(
        this.baseUrl + "api/v1/documents/" + documentType + "/Versions"
      );
    }
    return undefined;
  }

  getVersionsList(documentType: string, loandId: string, borrowerId?: string) {
    if (isPlatformBrowser(this.platformId)) {
      let url = this.baseUrl + "api/v1/loans/" + loandId + "/document-types/" + documentType + "/versions";
      // Append borrowerId only if it's defined and not null or empty
      if (borrowerId) {
        url += `?borrowerId=${borrowerId}`;
      }
      return this.http.get<ApiGridResponseModel<rdDocumentTypeVersion>>(url);
    }
    return undefined;
  }

  //https://localhost:7090/api/v1/loans/D04CB733-0D80-475E-8744-00F84EBB81F4/documents/E85B9D99-D85A-453B-9806-210DF7D96258/pages/9C5B3E9A-20C0-4910-B924-2D72FB25B235/details

  getPageDetails(loandId: string, documentId: string, pageId: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get<ApiGridResponseModel<PageDetail>>(
        this.baseUrl + "api/v1/loans/" + loandId + "/documents/" + documentId + "/pages/" + pageId + "/details"
      );
    }
    return undefined;
  }

  createVersion(loandId: string, versionData: CreateVersion) {
    if (isPlatformBrowser(this.platformId)) {
      // api/v1/loans/{LoanId}/documents
      return this.http.post<ApiGridResponseModel<CreateVersion>>(
        this.baseUrl + "api/v1/loans/" + loandId + "/documents",
        versionData // The request body
      );
    }
    return undefined;
  }

  // https://localhost:7090/api/v1/loans/56BBA41F-2EB8-415A-B45E-0090BB0239D1/borrowers
  createBorrowerVersion(loandId: string, borrowerVersionData: CreateBorrowerVersion) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post<ApiGridResponseModel<CreateVersion>>(
        this.baseUrl + "api/v1/loans/" + loandId + "/borrowers",
        borrowerVersionData // The request body
      );
    }
    return undefined;
  }

  resetChanges(resetChanges: ResetChanges) {
    if (isPlatformBrowser(this.platformId)) {
      // api/v1/loans/{LoanId}/documents
      return this.http.post<ApiGridResponseModel<ResetChanges>>(
        this.baseUrl + "api/v1/actions/reset",
        resetChanges // The request body
      );
    }
    return undefined;
  }

  saveChanges(saveChnages: saveChanges[]) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post<ApiGridResponseModel<saveChanges[]>>(
        this.baseUrl + "api/v1/actions",
        saveChnages // The request body
      );
    }
    return undefined;
  }

  // for Save progress Api
  saveProgress(_saveProgres: SaveProcess) {
    // https://localhost:7090/api/v1/actions/checkpoint
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post<ApiGridResponseModel<SaveProcess>>(
        this.baseUrl + "api/v1/actions/checkpoint",
        _saveProgres // The request body
      );
    }
    return undefined;
  }

  // https://localhost:7090/api/v1/loans/56BBA41F-2EB8-415A-B45E-0090BB0239D1/documents/E85B9D99-D85A-453B-9806-210DF7D96290/pages/thumbnails
  getPageThumbnailList(loandId: string, documentId: string, borrowerId?: string) {
    if (isPlatformBrowser(this.platformId)) {
      // Base URL without borrowerId
      let url = this.baseUrl + "api/v1/loans/" + loandId + "/documents/" + documentId + "/pages/thumbnails";
      console.log("url", url);
      // Append borrowerId only if it's defined and not null or empty
      if (borrowerId) {
        url += `?borrowerId=${borrowerId}`;
      }
      console.log("get version list", url);
      return this.http.get<ApiGridResponseModel<DocumentDetails[]>>(url);
    }
    return undefined;
  }

  // https://localhost:7090/api/v1/loans/56BBA41F-2EB8-415A-B45E-0090BB0239D1/documents?documentTypeId=DTSMORTC
  getDocumentVersionList(loanId: string, documentTypeId: string, borrowerId?: string) {
    if (isPlatformBrowser(this.platformId)) {
      // Construct the URL string first
      let url = `${this.baseUrl}api/v1/loans/${loanId}/documents?documentTypeId=${documentTypeId}`;

      // Append borrowerId only if it's defined and not empty
      if (borrowerId) {
        url += `&borrowerId=${borrowerId}`;
      }

      console.log("url", url); // Log the URL for debugging

      // Use the constructed URL in the http.get call
      return this.http.get<ApiGridResponseModel<DocumentTypeByVersions[]>>(url);
    }
    return undefined;
  }

  // https://localhost:7090/api/v1/loans/56BBA41F-2EB8-415A-B45E-0090BB0239D1/documents/E85B9D99-D85A-453B-9806-210DF7D96290/pages/9c5b3e9a-20c0-4910-b924-2d72fb25b239
  getImageDetail(loandId: string, documentId: string, pageId: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get<ApiGridResponseModel<PageImage>>(
        this.baseUrl + "api/v1/loans/" + loandId + "/documents/" + documentId + "/pages/" + pageId
      );
    }
    return undefined;
  }

  undoSaveChanges(_undoChnages: ResetChanges) {
    // https://localhost:7090/api/v1/actions/checkpoint
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post<ApiGridResponseModel<ResetChanges>>(
        this.baseUrl + "api/v1/actions/undo",
        _undoChnages // The request body
      );
    }
    return undefined;
  }
  updateStatusSubmit(submiChanges: SubmitChanges) {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.put<ApiGridResponseModel<SubmitChanges>>(
        this.LoanStatusUrl + submiChanges.projectId + "/loans/" + submiChanges.loanId + "/status?loanStatusId=" + 13,
        submiChanges // The request body
      );
    }
    return undefined;
  }
  // eslint-disable-
}
