export let BASE_URL = "";

if (typeof window !== "undefined") {
  if (window.location.hostname === "localhost") {
    BASE_URL = "https://localhost:7196/";
    BASE_URL = "https://bankincomedev.azurewebsites.net/";
  } else if (window.location.hostname === "qa.morgej.com" || window.location.hostname === "www.qa.morgej.com") {
    BASE_URL = "https://bankincomeqa.azurewebsites.net/";
  } else if (window.location.hostname === "dev.morgej.com" || window.location.hostname === "www.dev.morgej.com") {
    BASE_URL = "https://bankincomedev.azurewebsites.net/";
  } else if (
    window.location.hostname === "bsic.usdemo.msuite.ai" ||
    window.location.hostname === "www.bsic.usdemo.msuite.ai"
  ) {
    BASE_URL = "https://us-bankbackend-demo.azurewebsites.net/";
  } else if (
    window.location.hostname === "bsic.indemo.msuite.ai" ||
    window.location.hostname === "www.bsic.indemo.msuite.ai"
  ) {
    BASE_URL = "https://bankbackend-demo.azurewebsites.net/";
  } else {
    BASE_URL = "https://bankincomeqa.azurewebsites.net/";
  }
}

export const environment = {
  production: false,
  base_url: BASE_URL,
  loans_api: "https://msuiteloans-dev.azurewebsites.net/api/v1/",
  msalConfig: {
    auth: {
      clientId: "7d9b204f-da2c-4cda-b214-7d55a6e3bd5a",
    },
  },
  apiConfig: [
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/common/Common.Read",
        "https://flatworldsolb2c.onmicrosoft.com/common/Common.Write",
      ],
      uri: "https://msuitecommon-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: ["https://flatworldsolb2c.onmicrosoft.com/bsic/BSIC.Read"],
      uri: "https://bankincomedev.azurewebsites.net/",
    },
    {
      scopes: ["https://flatworldsolb2c.onmicrosoft.com/bsic/BSIC.Read"],
      uri: "https://bankincomedev.azurewebsites.net/",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/customers/Customers.Read",
        "https://flatworldsolb2c.onmicrosoft.com/customers/Customers.Write",
      ],
      uri: "https://msuitecustomer-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/storage/Files.Read",
        "https://flatworldsolb2c.onmicrosoft.com/storage/Files.Write",
      ],
      uri: "https://blob-service-dev.azurewebsites.net/api/v2",
    },
    // new scope below
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Users/Users.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Users/Users.Write",
      ],
      uri: "https://msuiteuser-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/loans/Loans.Read",
        "https://flatworldsolb2c.onmicrosoft.com/loans/Loans.Write",
      ],
      uri: "https://msuiteloans-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Rules-Review/Rules.Review.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Rules-Review/Rules.Review.Write",
      ],
      uri: "https://rulesenginevalidator-dev.azurewebsites.net/api/",
    },
  ],
  defaultScope: ["https://flatworldsolb2c.onmicrosoft.com/api/user.read"],
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1_Signin",
      resetPassword: "B2C_1_reset",
      editProfile: "B2C_1_edit_profile",
    },
    authorities: {
      signUpSignIn: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_Signin",
      },
      resetPassword: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_reset",
      },
      editProfile: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_edit_profile",
      },
    },
    authorityDomain: "flatworldsolb2c.b2clogin.com",
  },
};
